<template>
    <div class="container-box">
        <slot name="topTitle"/>
        <span class="container-box-udBor"/>
        <span class="container-box-lrBor"/>
        <span class="container-vertical"/>
        <span class="container-transverse"/>
        <span class="container-oblique container-oblique-lt"/>
        <span class="container-oblique container-oblique-rt"/>
        <span class="container-oblique container-oblique-lb"/>
        <span class="container-oblique container-oblique-rb"/>
        <div class="container-cont">
            <span class="container-cont-oblique container-cont-oblique-lt"/>
            <span class="container-cont-oblique container-cont-oblique-rt"/>
            <span class="container-cont-oblique container-cont-oblique-lb"/>
            <span class="container-cont-oblique container-cont-oblique-rb"/>
            <slot/>
        </div>
    </div>
</template>

<script>
    export default {
        name: "BaseBoxMoudle",
    }
</script>

<style scoped lang="scss">
    /*#1E3860  #162C4B   */
    $containerColor01: #132844;
    $containerColor02: #132844;
    $containerColor03: #4D4D4D;
    $contBorderwid: 2;
    .container-box {
        position: relative;
        width: 100%;
        padding: 6px 6px;
        height: 100%;
        .container-box-udBor, .container-box-lrBor {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 0;

            &:before, &:after {
                position: absolute;
                content: "";
                border-style: solid;

                z-index: 0;
            }

        }

        .container-box-udBor {
            width: calc(100% - 70px);
            /*height: calc(100% + 1px);*/
            height: 100%;

            &:before, &:after {
                left: 0;
                width: 100%;
                height: 6px;

            }

            &:before {
                top: 0;
                border-width: ($contBorderwid + px) ($contBorderwid + px) 0 ($contBorderwid + px);
                border-color: $containerColor01 $containerColor01 transparent $containerColor01;
            }

            &:after {
                bottom: 0;
                border-width: 0 ($contBorderwid + px) ($contBorderwid + px) ($contBorderwid + px);
                border-color: transparent $containerColor01 $containerColor01 $containerColor01;
            }
        }

        .container-box-lrBor {
            /*width: calc(100% + 1px);*/
            width: 100%;
            height: calc(100% - 68px);

            &:before, &:after {
                top: 0;
                width: 6px;
                height: 100%;
            }

            &:before {
                left: 0;
                border-width: ($contBorderwid + px) 0 ($contBorderwid + px) ($contBorderwid + px);
                border-color: $containerColor01 transparent $containerColor01 $containerColor01;
            }

            &:after {
                right: 0;
                border-width: ($contBorderwid + px) ($contBorderwid + px) ($contBorderwid + px) 0;
                border-color: $containerColor01 $containerColor01 $containerColor01 transparent;
            }


        }

        .container-vertical {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;

            &:before, &:after {
                content: "";
                position: absolute;
                top: 0;
                width: 15px;
                /*width: 3%;*/
                height: 100%;
                border-width: ($contBorderwid + px) 0;
                border-style: solid;
                border-color: $containerColor02 transparent $containerColor02 transparent;
            }

            &:before {
                left: 11px;
            }

            &:after {
                right: 11px;
            }


        }

        .container-transverse {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;

            &:before, &:after {
                content: "";
                position: absolute;
                left: 0;
                width: 100%;
                height: 15px;
                border-width: 0 ($contBorderwid + px);
                border-style: solid;
                border-color: transparent $containerColor02 transparent $containerColor02;
            }

            &:before {
                top: 11px;
            }

            &:after {
                bottom: 11px;
            }


        }

        .container-oblique {
            position: absolute;
            $wid: 15;
            width: $wid + px;
            height: $wid + px;
            border-top: ($contBorderwid + px) solid $containerColor02;
            transform-origin: center center;
        }

        .container-oblique-lt {
            $lef: 3;
            top: $lef + px;
            left: $lef + px;
            transform: rotate(-45deg);
        }

        .container-oblique-rt {
            $lef: 3;
            top: $lef + px;
            right: $lef + px;
            transform: rotate(45deg);
        }

        .container-oblique-lb {
            $lef: 3;
            bottom: $lef + px;
            left: $lef + px;
            transform: rotate(-135deg);
        }

        .container-oblique-rb {
            $lef: 3;
            bottom: $lef + px;
            right: $lef + px;
            transform: rotate(135deg);
        }

        .container-cont {
            background: linear-gradient(180deg, rgba(46, 167, 224, 0.05), rgba(46, 167, 224, 0.06) 60%, rgba(46, 167, 224, 0.1));
            min-height: 100px;
            width: 100%;
            height: 100%;
            position: relative;
            padding: 10px 15px;


            .container-cont-oblique {
                position: absolute;
                $wid: 13;
                width: $wid + px;
                height: $wid + px;
                border-top: 2px solid $containerColor03;

            }

            .container-cont-oblique-lt {
                $lef: 5;
                top: $lef + px;
                left: $lef + px;
                transform: rotate(-45deg);
            }

            .container-cont-oblique-rt {
                $lef: 5;
                top: $lef + px;
                right: $lef + px;
                transform: rotate(45deg);
            }

            .container-cont-oblique-lb {
                $lef: 5;
                left: $lef + px;
                bottom: $lef + px;
                transform: rotate(-135deg);
            }

            .container-cont-oblique-rb {
                $lef: 5;
                right: $lef + px;
                bottom: $lef + px;
                transform: rotate(135deg);
            }


        }

    }
</style>
