<template>
  <section>
    <a-row type="flex" justify="space-between" class="container">
      <boxTitleMoudle
        :title="'塔吊设备'"
        style="width: 15.5%"
        class="towerEquiment"
        :left="'0px'"
      >
        <li
          :class="
            index == towerCurrent
              ? 'towerName m-b-10 active'
              : 'towerName m-b-10'
          "
          @click="changeTower(index)"
          v-for="(item, index) in towerList"
          :key="index"
        >
          <span class="icon">
            <img
              style="width: 20px"
              src="../assets/image/bg-image/tower.png"
              alt=""
            />
          </span>
          <span class="name">
            {{ item.deviceName }}
          </span>
          <span class="leftLine" v-if="index == towerCurrent"></span>
          <span class="rightLine" v-if="index == towerCurrent"></span>
        </li>
      </boxTitleMoudle>
      <a-col style="width: 45.5%; height: 100%" class="monitor">
        <boxTitleMoudle
          :left="'-290px'"
          class="monitor_video"
          :title="'监控画面'"
        >
          <section style="width: 100%; height: 100%; padding: 10px">
            <img
              src="../assets/image/bg-image/monitorPic.png"
              alt=""
              style="width: 100%; height: 100%"
            />
          </section>
        </boxTitleMoudle>

        <boxTitleMoudle :left="'-290px'" class="real_data" :title="'实时数据'">
          <section class="real_list">
            <section class="data_box">
              <section class="icon">
                <img src="../assets/image/bg-image/icon/height.png" alt="" />
              </section>
              <section class="data">
                <section style="width: 100%; text-align: center">
                  <section>
                    <span class="digital">{{ height }}</span> m
                    <!-- {{ TowerCraneRealTimeData[0].value}}{{ TowerCraneRealTimeData[0].unit }} -->
                  </section>
                  <section>
                    <!-- {{ TowerCraneRealTimeData[0].name }} -->
                    塔身高度
                  </section>
                </section>
              </section>
            </section>
            <section class="data_box">
              <section class="icon">
                <img src="../assets/image/bg-image/icon/moment.png" alt="" />
              </section>
              <section class="data">
                <section style="width: 100%; text-align: center">
                  <section>
                    <span class="digital">{{ moment }}</span
                    >%
                    <!-- {{ TowerCraneRealTimeData[1].value
                    }}{{ TowerCraneRealTimeData[1].unit }} -->
                  </section>
                  <section>
                    力矩比
                    <!-- {{ TowerCraneRealTimeData[1].name }} -->
                  </section>
                </section>
              </section>
            </section>
            <section class="data_box">
              <section class="icon">
                <img src="../assets/image/bg-image/icon/wind.png" alt="" />
              </section>
              <section class="data">
                <section style="width: 100%; text-align: center">
                  <section>
                    <span class="digital">{{ wind }}</span
                    >m/s
                    <!-- {{ TowerCraneRealTimeData[2].value
                    }}{{ TowerCraneRealTimeData[2].unit }} -->
                  </section>
                  <section>
                    风速
                    <!-- {{ TowerCraneRealTimeData[2].name }} -->
                  </section>
                </section>
              </section>
            </section>
            <section class="data_box">
              <section class="icon">
                <img src="../assets/image/bg-image/icon/crane.png" alt="" />
              </section>
              <section class="data">
                <section style="width: 100%; text-align: center">
                  <section>
                    <span class="digital">{{ before }}</span
                    >m/ <span class="digital">{{ after }}</span
                    >m
                    <!-- {{ TowerCraneRealTimeData[3].value
                    }}{{ TowerCraneRealTimeData[3].unit }}/
                    {{ TowerCraneRealTimeData[4].value
                    }}{{ TowerCraneRealTimeData[4].unit }} -->
                  </section>
                  <section>
                    前臂/后臂
                    <!-- {{ TowerCraneRealTimeData[3].name }}/{{
                      TowerCraneRealTimeData[4].name
                    }} -->
                  </section>
                </section>
              </section>
            </section>
            <section class="data_box m-t-30">
              <section class="icon">
                <img src="../assets/image/bg-image/icon/rotation.png" alt="" />
              </section>
              <section class="data">
                <section style="width: 100%; text-align: center">
                  <section>
                    <span class="digital">{{ rotation }}</span
                    >°
                    <!-- {{ TowerCraneRealTimeData[5].value
                    }}{{ TowerCraneRealTimeData[5].unit }} -->
                  </section>
                  <section>
                    回转
                    <!-- {{ TowerCraneRealTimeData[5].name }} -->
                  </section>
                </section>
              </section>
            </section>
            <section class="data_box m-t-30">
              <section class="icon">
                <img src="../assets/image/bg-image/icon/lifting.png" alt="" />
              </section>
              <section class="data">
                <section style="width: 100%; text-align: center">
                  <section>
                    <span class="digital">{{ liftings }}</span
                    >m
                    <!-- {{ TowerCraneRealTimeData[6].value
                    }}{{ TowerCraneRealTimeData[6].unit }} -->
                  </section>
                  <section>
                    垂直倾角
                    <!-- {{ TowerCraneRealTimeData[6].name }} -->
                  </section>
                </section>
              </section>
            </section>
            <section class="data_box m-t-30">
              <section class="icon">
                <img src="../assets/image/bg-image/icon/weight.png" alt="" />
              </section>
              <section class="data">
                <section style="width: 100%; text-align: center">
                  <section>
                    <span class="digital">{{ weight }}</span
                    >kg
                    <!-- {{ TowerCraneRealTimeData[7].value
                    }}{{ TowerCraneRealTimeData[7].unit }} -->
                  </section>
                  <section>
                    吊重
                    <!-- {{ TowerCraneRealTimeData[7].name }} -->
                  </section>
                </section>
              </section>
            </section>
            <section class="data_box m-t-30">
              <section class="icon">
                <img src="../assets/image/bg-image/icon/swing.png" alt="" />
              </section>
              <section class="data">
                <section style="width: 100%; text-align: center">
                  <section>
                    <span class="digital">{{ swing }}</span
                    >°
                    <!-- {{ TowerCraneRealTimeData[8].value
                    }}{{ TowerCraneRealTimeData[8].unit }} -->
                  </section>
                  <section>
                    小车幅度
                    <!-- {{ TowerCraneRealTimeData[8].name }} -->
                  </section>
                </section>
              </section>
            </section>
            <section class="data_box m-t-30">
              <section class="icon">
                <img src="../assets/image/bg-image/icon/lifting.png" alt="" />
              </section>
              <section class="data">
                <section style="width: 100%; text-align: center">
                  <section>
                    <span class="digital">{{ lifting }}</span
                    >°
                    <!-- {{ TowerCraneRealTimeData[9].value
                    }}{{ TowerCraneRealTimeData[9].unit }} -->
                  </section>
                  <section>
                    水平倾角
                    <!-- {{ TowerCraneRealTimeData[9].name }} -->
                  </section>
                </section>
              </section>
            </section>
            <section class="data_box m-t-30">
              <section class="icon">
                <img src="../assets/image/bg-image/icon/height.png" alt="" />
              </section>
              <section class="data">
                <section style="width: 100%; text-align: center">
                  <section>
                    <span class="digital">{{ heights }}</span
                    >m
                    <!-- {{ TowerCraneRealTimeData[10].value
                    }}{{ TowerCraneRealTimeData[10].unit }} -->
                  </section>
                  <section>
                    吊钩高度
                    <!-- {{ TowerCraneRealTimeData[10].name }} -->
                  </section>
                </section>
              </section>
            </section>
            <section class="data_box m-t-30">
              <section class="icon">
                <img src="../assets/image/bg-image/icon/angle.png" alt="" />
              </section>
              <section class="data">
                <section style="width: 100%; text-align: center">
                  <section>
                    <span class="digital">{{ status }}</span>
                    <!-- {{ TowerCraneRealTimeData[11].value
                    }} -->
                  </section>
                  <section>
                    设备状态
                    <!-- {{ TowerCraneRealTimeData[11].name }} -->
                  </section>
                </section>
              </section>
            </section>
          </section>
        </boxTitleMoudle>
      </a-col>
      <a-col style="width: 38%" class="warnningData">
        <boxTitleMoudle :left="'-230px'" class="warnning" :title="'设备预警'">
          <div class="warm_data">
            <span
              >今日预警: <span style="color: red" class="digital">99</span>
            </span>
            <span
              >累计预警: <span style="color: red" class="digital">99</span>
            </span>
          </div>

          <section style="height: 90%">
            <div style="width: 100%; text-align: center; height: 100%">
              <li
                class="flex-bet"
                style="
                  color: #95adce;
                  display: flex;
                  justify-content: space-between;
                  text-align: center;
                "
              >
                <span style="width: flex:1">事件名称</span>
                <span style="width: flex:1">事件描述</span>
                <span style="width: flex:1">预警时间</span>
                <span style="width: flex:1">预警地点</span>
              </li>
              <div style="overflow: hidden; height: 90%">
                <div style="height: 100%">
                  <!-- <li
                    class="flex-bet safeData"
                    v-for="(item, index) in InOutAlarmRecordList"
                    :key="index"
                  >
                    <span class="text-overflow" style="width: 20%">{{
                      item.alarmName
                    }}</span>
                    <span
                      class="text-overflow"
                      :title="item.alarmMessage"
                      style="width: 30%"
                      >{{ item.alarmMessage }}</span
                    >
                    <span
                      class="text-overflow"
                      :title="item.createTime"
                      style="width: 30%"
                      >{{ item.createTime }}</span
                    >
                    <span
                      class="text-overflow"
                      :title="item.alarmArea"
                      style="width: 20%"
                      >{{ item.alarmArea }}</span
                    >
                  </li> -->

                  <textData :length="6" :textData="InOutAlarmRecordList">
                    <template v-slot:alarmName="slotProps">
                      <span
                        :title="slotProps.val"
                        class="text-overflow"
                        style="flex: 1; height: 30px; line-height: 30px"
                      >
                        {{ slotProps.val }}
                      </span>
                    </template>
                    <template v-slot:alarmMessage="slotProps">
                      <span
                        :title="slotProps.val"
                        class="text-overflow"
                        style="flex: 1; height: 30px; line-height: 30px"
                      >
                        {{ slotProps.val }}
                      </span>
                    </template>
                    <template v-slot:createTime="slotProps">
                      <span
                        :title="slotProps.val"
                        class="text-overflow"
                        style="flex: 1; height: 30px; line-height: 30px"
                      >
                        {{ slotProps.val }}
                      </span>
                    </template>
                    <template v-slot:alarmArea="slotProps">
                      <span
                        :title="slotProps.val"
                        class="text-overflow"
                        style="flex: 1; height: 30px; line-height: 30px"
                      >
                        {{ slotProps.val }}
                      </span>
                    </template>
                  </textData>
                </div>
              </div>
            </div>
          </section>
        </boxTitleMoudle>

        <boxTitleMoudle
          :left="'-230px'"
          class="warnning_tendency"
          :title="'预警趋势'"
        >
          <span class="echarts_title">预警次数</span>
          <div id="tendency" style="width: 100%; height: 220px"></div>
        </boxTitleMoudle>
        <boxTitleMoudle
          :left="'-230px'"
          class="warnning_tendency"
          :title="'异常趋势'"
        >
          <span class="echarts_title">异常次数</span>
          <div id="unusual" style="width: 100%; height: 220px"></div>
        </boxTitleMoudle>
      </a-col>
    </a-row>
  </section>
</template>
<script>
import boxTitleMoudle from "@/components/BaseBoxTitleMoudle.vue";
import textData from "@/components/textData.vue";
export default {
  data() {
    return {
      towerList: [
        // { name: "塔吊监测系统001", id: 1 },
        // { name: "塔吊监测系统002", id: 1 },
        // { name: "塔吊监测系统003", id: 1 },
        // { name: "塔吊监测系统004", id: 1 },
        // { name: "塔吊监测系统005", id: 1 },
        // { name: "塔吊监测系统006", id: 1 },
        // { name: "塔吊监测系统007", id: 1 }
      ],
      TowerCraneRealTimeData: [],
      towerCurrent: 0,
      height: "",
      moment: "",
      lifting: "",
      liftings: "",
      wind: "",
      before: "",
      after: "",
      rotation: "",
      weight: "",
      swing: "",
      status: "",
      heights: "",
      InOutAlarmRecordList: [],

      // 计时器
      scrollTime: null,
      distance: 0,
    };
  },
  mounted() {
    this.drawTendencyData();
    this.drawUnusualData();
    this.ScrollUp();
  },
  components: {
    boxTitleMoudle,
    textData,
  },
  created() {
    this.deviceList(); //塔吊列表
    this.getTowerCraneRealTimeData(); //实时数据
    // 塔吊预警
    this.getInOutAlarmRecordList();
    // 异常趋势
    this.getInOutAlarmTrend3();
    // 预警趋势
    this.getInOutAlarmTrend2();
  },
  methods: {
    // 设备预警移入
    addActive() {
      clearInterval(this.scrollTime);
    },
    // 设备预警移出
    removeActive() {
      this.ScrollUp();
    },
    ScrollUp() {
      let offsetHeight = this.$refs.contlist.offsetHeight;
      let contBox = document.getElementById("contBox");
      //设置位移
      this.scrollTime = setInterval(() => {
        let distanceData = this.distance - 40;
        this.distance = distanceData;
        // 如果位移超过文字宽度，则回到起点
        if (-distanceData >= offsetHeight) {
          this.distance = 0;
          distanceData = 0;
        }
        contBox.style.transform = "translateY(" + distanceData + "px)";
      }, 3000);
    },
    // 塔吊列表
    deviceList() {
      this.$api.deviceList({ typeId: 4 }).then((res) => {
        if (res.code == "200") {
          this.towerList = res.data;
        }
      });
    },
    // 塔吊预警

    getInOutAlarmRecordList() {
      this.$api.getInOutAlarmRecordList({ type: "2" }).then((res) => {
        if (res.code == "200") {
          if (res.data && res.data.length > 0) {
            res.data.forEach((item) => {
              this.InOutAlarmRecordList.push({
                alarmName: item.alarmName,
                alarmMessage: item.alarmMessage,
                createTime: item.createTime,
                alarmArea: item.alarmArea,
              });
            });
          }
        }
      });
    },
    // 实时数据
    getTowerCraneRealTimeData() {
      this.$api.getTowerCraneRealTimeData().then((res) => {
        if (res.code == "200") {
          this.TowerCraneRealTimeData = res.data;
          this.height = this.TowerCraneRealTimeData[0].value;
          this.moment = this.TowerCraneRealTimeData[1].value;
          this.wind = this.TowerCraneRealTimeData[2].value;
          this.before = this.TowerCraneRealTimeData[3].value;
          this.after = this.TowerCraneRealTimeData[4].value;
          this.rotation = this.TowerCraneRealTimeData[5].value;
          this.liftings = this.TowerCraneRealTimeData[6].value;
          this.weight = this.TowerCraneRealTimeData[7].value;
          this.swing = this.TowerCraneRealTimeData[8].value;
          this.lifting = this.TowerCraneRealTimeData[9].value;
          this.heights = this.TowerCraneRealTimeData[10].value;
          this.status = this.TowerCraneRealTimeData[11].value;
        }
      });
    },
    changeTower(key) {
      this.towerCurrent = key;
    },
    // 预警趋势
    getInOutAlarmTrend2() {
      this.$api.getInOutAlarmTrend({ type: "2" }).then((res) => {
        if (res.code == "200") {
          this.drawTendencyData(res.data);
          // this.InOutAlarmRecordList = res.data.slice(0, 6);
        }
      });
    },
    // 异常趋势
    getInOutAlarmTrend3() {
      this.$api.getInOutAlarmTrend({ type: "3" }).then((res) => {
        if (res.code == "200") {
          this.drawUnusualData(res.data);
        }
      });
    },

    // 异常趋势
    drawUnusualData(data) {
      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(document.getElementById("unusual"));
      let orgIcon =
        '<svg width="11" height="10" viewBox="0 0 11 10" xmlns="http://www.w3.org/2000/svg"><g transform="translate(.719 .164)" fill="none" fill-rule="evenodd"><circle stroke="#D36218" fill="#323538" cx="4.833" cy="4.833" r="4.333"/><circle fill="#D36218" cx="4.833" cy="4.833" r="1.758"/></g></svg>';
      orgIcon = encodeURIComponent(orgIcon); // 转译
      orgIcon = "data:image/svg+xml;utf8," + orgIcon; // 添加url前缀
      orgIcon = "image://" + orgIcon; // 添加ECharts需要的前缀
      let dateTime = [];
      let value = [];
      let offline = [];
      let fault = [];
      if (data && data.length > 0) {
        data.forEach((item) => {
          dateTime.push(item.dateTime);
          value.push(item.value);
          offline.push(item.offline);
          fault.push(item.fault);
        });
      }
      // 绘制图表
      myChart.setOption({
        tooltip: {
          trigger: "item",
          formatter: function (arg) {
            let html = dateTime[arg["dataIndex"]] + "<br>";
            html += "离线:" + offline[arg["dataIndex"]] + "<br>";
            html += "故障:" + fault[arg["dataIndex"]] + "<br>";

            return html;
          },
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: dateTime,
          axisLine: {
            lineStyle: {
              // 设置x轴颜色
              color: "rgba(255, 255, 255, 0.5)",
            },
          },

          splitLine: {
            show: true,
            lineStyle: {
              color: "rgba(255, 255, 255, 0.05)",
            },
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "1%",
          top: "15%",
          containLabel: true,
        },
        yAxis: {
          type: "value",
          axisLine: {
            lineStyle: {
              // 设置x轴颜色
              color: "rgba(255, 255, 255, 0.5)",
            },
          },

          splitLine: {
            show: true,
            lineStyle: {
              color: "rgba(255, 255, 255, 0.05)",
            },
          },
        },
        series: [
          {
            data: value,
            type: "line",
            smooth: true,
            symbolSize: 10,
            symbol: orgIcon,
            // areaStyle: { color: "rgba(211,98,24,1)" },
            itemStyle: {
              normal: {
                // 拐点上显示数值
                label: {
                  show: false,
                },
                borderColor: "rgba(87,247,255,1)", // 拐点边框颜色
                lineStyle: {
                  //   width: 5, // 设置线宽
                  type: "dotted", //'dotted'虚线 'solid'实线
                },
              },
            },
          },
        ],
        color: ["rgba(211,98,24,1)"],
      });

      //建议加上以下这一行代码，不加的效果图如下（当浏览器窗口缩小的时候）。超过了div的界限（红色边框）
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },

    // 预警趋势
    drawTendencyData(data) {
      // 橙色
      let orgIcon =
        '<svg width="11" height="10" viewBox="0 0 11 10" xmlns="http://www.w3.org/2000/svg"><g transform="translate(.719 .164)" fill="none" fill-rule="evenodd"><circle stroke="#D36218" fill="#323538" cx="4.833" cy="4.833" r="4.333"/><circle fill="#D36218" cx="4.833" cy="4.833" r="1.758"/></g></svg>';
      orgIcon = encodeURIComponent(orgIcon); // 转译
      orgIcon = "data:image/svg+xml;utf8," + orgIcon; // 添加url前缀
      orgIcon = "image://" + orgIcon; // 添加ECharts需要的前缀
      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(document.getElementById("tendency"));
      let dateTime = [];
      let value = [];
      if (data && data.length > 0) {
        data.forEach((item) => {
          dateTime.push(item.dateTime);
          value.push(item.value);
        });
      }
      // 绘制图表
      myChart.setOption({
        tooltip: {
          trigger: "item",
          formatter: function (arg) {
            let html = dateTime[arg["dataIndex"]] + "<br>";
            html += "预警数:" + value[arg["dataIndex"]] + "<br>";

            return html;
          },
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: dateTime,
          axisLine: {
            lineStyle: {
              // 设置x轴颜色
              color: "rgba(255, 255, 255, 0.5)",
            },
          },

          splitLine: {
            show: true,
            lineStyle: {
              color: "rgba(255, 255, 255, 0.05)",
            },
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "1%",
          top: "20%",
          containLabel: true,
        },
        yAxis: {
          type: "value",
          axisLine: {
            lineStyle: {
              // 设置x轴颜色
              color: "rgba(255, 255, 255, 0.5)",
            },
          },

          splitLine: {
            show: true,
            lineStyle: {
              color: "rgba(255, 255, 255, 0.05)",
            },
          },
        },
        series: [
          {
            data: value,
            type: "line",
            smooth: true,
            // areaStyle: { color: "rgba(211,98,24,1)" },
            symbolSize: 10,
            symbol: orgIcon,
            itemStyle: {
              normal: {
                // 拐点上显示数值
                label: {
                  show: false,
                },
                borderColor: "rgba(211,98,24,1)", // 拐点边框颜色
                lineStyle: {
                  width: 1, // 设置线宽
                  type: "dotted", //'dotted'虚线 'solid'实线
                },
              },
            },
          },
        ],
        color: ["rgba(211,98,24,1)"],
      });

      //建议加上以下这一行代码，不加的效果图如下（当浏览器窗口缩小的时候）。超过了div的界限（红色边框）
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },
  },
};
</script>
<style lang="scss" scoped>
/**
 * 字体
 */
@font-face {
  font-family: "digital";
  src: url("../views/monitor_screen/font/digital.ttf") format("truetype");
}
.digital {
  font-family: digital;
  font-size: 20px;
  font-style: normal;
  font-weight: 800;
}
.contBox {
  // transition: all 0.5s ease-in-out;
  transition: all 0.5s linear;
}
li {
  list-style: none;
}
.container {
  height: 98%;
  margin-top: 30px;
  // 塔吊设备
  .towerEquiment {
    background: url("../assets/image/bg-image/left_bg.png") no-repeat;
    background-size: 100% 100%;
    // padding: 80px 20px 0px 20px;
    position: relative;
    .title {
      position: absolute;
      top: -46px;
      left: 2px;
      img {
        width: 100%;
      }
    }
    .towerName {
      height: 40px;
      line-height: 40px;
      background-color: rgba(28, 61, 108, 1);
      color: rgba(255, 255, 255, 1);
      width: 100%;
      display: flex;
      justify-content: space-around;
      cursor: pointer;
      position: relative;
      margin-top: 20px;
      //   &:hover {
      //     background-color: rgba(63, 114, 186, 0.4);
      //   }
      .icon {
        width: 20%;
        text-align: center;
      }
      .name {
        width: 70%;
        font-size: 14px;
      }
      .leftLine {
        display: inline-block;
        height: 40px;
        width: 2px;
        background-color: rgba(63, 114, 186, 1);
        position: absolute;
        left: -6px;
      }
      .rightLine {
        display: inline-block;
        height: 40px;
        width: 2px;
        background-color: rgba(63, 114, 186, 1);
        position: absolute;
        right: -8px;
      }
    }
    .active {
      background: rgba(63, 114, 186, 1);
    }
  }
  //   视频监控
  .monitor {
    .monitor_video {
      position: relative;
      height: 58%;

      background-size: 100% 100%;

      margin-bottom: 20px;
      .title {
        position: absolute;
        top: -43px;
        left: 2px;
        img {
          width: 56%;
        }
      }
    }
    .real_data {
      position: relative;
      height: 40%;

      background-size: 100% 100%;

      color: #ccc;
      .title {
        position: absolute;
        top: -43px;
        left: 2px;
        img {
          width: 56%;
        }
      }
      .real_list {
        display: flex;
        justify-self: flex-start;
        flex-wrap: wrap;
        padding: 40px;
        .data_box {
          display: flex;
          justify-content: flex-start;
          width: 25%;
          .icon {
            display: flex;
            justify-content: center; /* 水平居中 */
            align-items: center; /* 垂直居中 */
            width: 40%;
            img {
              width: 60%;
            }
          }
          .data {
            display: flex;
            width: 50%;
            justify-content: center; /* 水平居中 */
            align-items: center; /* 垂直居中 */
          }
        }
      }
    }
  }

  //   预警
  .warnningData {
    .warnning {
      height: 32%;
      margin-bottom: 20px;

      background-size: 100% 100%;

      position: relative;
      .title {
        position: absolute;
        top: -43px;
        left: 2px;
        img {
          width: 56%;
        }
      }
      .warm_data {
        width: 27%;
        display: flex;
        justify-content: center;
        position: absolute;
        right: 0;
        top: 10px;
        span {
          flex: 1;
        }
      }
      .flex-bet {
        display: flex;
        justify-content: space-between;
        text-align: center;
        margin-top: 30px;
        span {
          flex: 1;
        }
      }
      .safeData {
        background: rgba(39, 73, 121, 1);
        margin-top: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 17.6%;
      }
    }
    .warnning_tendency {
      height: 32%;
      margin-bottom: 20px;

      position: relative;
      .title {
        position: absolute;
        top: -43px;
        left: 2px;
        img {
          width: 56%;
        }
      }
      .echarts_title {
        float: right;
        &::before {
          content: "";
          width: 8px;
          display: inline-block;
          height: 8px;
          background: rgba(211, 98, 24, 1);
          margin-right: 8px;
          border-radius: 1px;
        }
      }
    }
    .unusual_tendency {
      height: 32%;

      position: relative;
      .title {
        position: absolute;
        top: -43px;
        left: 2px;
        img {
          width: 56%;
        }
      }

      .echarts_title {
        float: right;
        &::before {
          content: "";
          width: 8px;
          display: inline-block;
          height: 8px;
          background: rgba(87, 247, 255, 1);
          margin-right: 8px;
          border-radius: 1px;
        }
      }
    }
  }
}
</style>
