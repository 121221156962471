<template>
  <boxMoudle>
    <slot />
    <template #topTitle>
      <div class="topTitleBox" :style="{ left: left ? left : '0px' }">
        <div class="topTitle-cont">
          <p class="m-textOverflow">{{ title }}</p>
  
        </div>
      </div>
    </template>
  </boxMoudle>
</template>

<script>
import boxMoudle from "@/components/containerBox.vue";

export default {

  name: "BaseBoxTitleMoudle",
  props: {
    title: {
      type: String,
      default: () => "标题",
    },
    left: {
      type: String,
      default: () => "-144px",
    },
  },
 data(){
   return{
        titleName:''
   }
 },
  components: {
    boxMoudle,
  },
  created() {
    this.queryProject()
  },
  methods:{
     queryProject(){
            this.$api.queryProject().then(res =>{
                if(res.code === 200){
                    console.log(res.data.projectName);
                    this.titleName=res.data.projectName
                }
            })
        },
  },
  
};
</script>

<style scoped lang="scss">
.topTitleBox {
  position: absolute;
  top: -14.5px;
  left: 0;
  width: 100%;
  z-index: 100;
  cursor: default;

  .topTitle-cont {
    margin: 0 auto;
    width: 200px;
    height: 30px;
    background: url("../assets/image/home/box_top_001.png") no-repeat center;
    background-size: 100%;

    > p {
      text-align: center;
      line-height: 28px;
      font-weight: 700;
      font-size: 16px;
      color: #fff;
    }
  }
}
</style>
