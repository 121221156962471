<template>
  <section>
    <li
      v-on:mouseenter.stop="Active(ind)"
      v-on:mouseleave.stop="removeActive()"
      :class="{ content: true, 'selectStatus': selectStatusInd==ind }"
      v-for="(item, ind) in data"
      :key="ind"
      :item="item"
    >
      <slot v-for="val in Object.keys(item)" :name="val" :val="item[val]">{{
        item[val]
      }}</slot>
    </li>
  </section>
</template>

<script>
export default {
  props: ["textData", "length"],
  watch: {
    textData: {
      handler(v) {
        clearInterval(this.scrollTime);
        this.list = v;
        this.getList();
        this.scrollTime = setInterval(() => {
          this.getList();
        }, 5000);
      },
      deep: true, // 深度监听父组件传过来对象变化
    },
  },
  data() {
    return {
      list: [],
      sliceArrData: [],
      ind: 0,
      data: [],
      scrollTime: null,
      selectStatusInd: null,
    };
  },
  beforeDestroy() {
    if (this.scrollTime) {
      clearInterval(this.scrollTime); //清除我们的定时器
    }
  },
  mounted() {
    this.$once("hook:beforeDestroy", () => {
      clearInterval(this.scrollTime);
    });
  },
  methods: {
    //   移入
    Active(ind) {
      clearInterval(this.scrollTime);

      this.selectStatusInd = ind;
    },
    // 移出
    removeActive() {
      this.selectStatusInd = null;
      this.scrollTime = setInterval(() => {
        this.getList();
      }, 5000);
    },
    // 分割数组
    sliceArr(arr, len) {
      let newArr = []; //首先创建一个新的空数组。用来存放分割好的数组
      for (let i = 0; i < arr.length; ) {
        //注意：这里与for循环不太一样的是，没有i++
        newArr.push(arr.slice(i, (i += len)));
      }
      return newArr;
    },
    getList() {
      this.sliceArrData = this.sliceArr(this.list, this.length);
      let list = [...this.sliceArrData];
      if (this.ind >= list.length) {
        this.ind = 0;
      }
      this.data = list[this.ind];
      this.ind++;
    },
  },
};
</script>

<style scoped lang='scss' >
.content {
  width: 100%;
  display: flex;
  justify-content: space-between;
  text-align: center;
  // height: 30px;
  // line-height: 30px;
  background: rgba(39, 73, 121, 1);
  margin-top: 10px;
  cursor: pointer;
}
.content.selectStatus {
  
  background: #1c3d6c;

}
.flex-bet {
  display: flex;
  justify-content: space-between;
  text-align: center;
  height: 30px;
  line-height: 30px;

  span {
    flex: 1;
  }
}
.safeData {
  background: rgba(39, 73, 121, 1);
  margin-top: 4px;
  height: 14.6%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>

